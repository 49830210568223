<template>
  <slideover title="Update Payment" data-testid="Update Payment">
    <EditForm
      v-if="state.showUpdatePaymentForm"
      @cancel="handleCancel"
      @paymentDetailsId="paymentDetailsId"
    />
    <CardList
      v-else
      :paymentDetails="paymentDetails"
      @onEdit="onEdit"
      @onDelete="onDelete"
      @onCancelSubscription="cancelSubscription"
    />
  </slideover>
</template>

<script>
import { onMounted, reactive } from "vue";
import Slideover from "@/common/components/slideover";

import useSubscriptionRepository from "@/composable/useSubscriptionRepository";

import EditForm from "./_editForm.vue";
import CardList from "./_list.vue";

export default {
  components: {
    Slideover,
    EditForm,
    CardList,
  },
  setup(_, { emit }) {
    const {
      loading,
      deleteCard,
      paymentDetails,
      getPaymentDetails,
      updatePaymentDetails,
    } = useSubscriptionRepository();

    const state = reactive({
      showUpdatePaymentForm: false,
    });

    const handleCancel = () => {
      state.showUpdatePaymentForm = false;
    };

    const onEdit = () => {
      state.showUpdatePaymentForm = true;
    };

    const onDelete = (id) => {
      deleteCard(id);
    };

    const paymentDetailsId = (id) => {
      updatePaymentDetails(id);
      state.showUpdatePaymentForm = false;
    };

    const onCancelSubscription = () => {
      emit("cancelSubscription");
    };

    onMounted(() => {
      getPaymentDetails();
    });

    return {
      state,
      loading,
      paymentDetails,
      getPaymentDetails,
      updatePaymentDetails,
      paymentDetailsId,
      handleCancel,
      onEdit,
      onDelete,
      onCancelSubscription,
    };
  },
};
</script>
