<template>
  <section class="mt-8" aria-labelledby="billing_history_heading">
    <div class="bg-white pt-6 shadow sm:overflow-hidden sm:rounded-md">
      <div class="px-4 sm:px-6">
        <h2
          id="billing_history_heading"
          class="text-left text-lg font-medium leading-6 text-gray-900"
        >
          Billing history
        </h2>
      </div>
      <div class="mt-6 flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <div class="overflow-hidden border-t border-gray-200">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Number
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      class="relative px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      <span class="sr-only">Download</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white text-left">
                  <tr v-for="payment in payments" :key="payment.id">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      <time :datetime="payment.Created">{{
                        formatDate(payment.Created)
                      }}</time>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <span
                        class="inline-flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800"
                      >
                        {{ payment.InvoiceNumber }}
                      </span>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ payment.CustomerName }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      ${{ payment?.Total?.toFixed(2) }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                    >
                      <a
                        :href="payment.ReceiptUrl"
                        target="_blank"
                        v-tippy="{
                          content: 'View Receipt',
                          animation: 'scale',
                          animateFill: false,
                          theme: 'tomato',
                        }"
                        class="focus:outline-none mr-4 inline-flex cursor-pointer items-center rounded-md border border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800"
                      >
                        <DocumentTextIcon
                          class="h-5 w-5 flex-shrink-0 text-gray-400"
                        />
                      </a>
                      <a
                        :href="payment.InvoicePDFl"
                        v-tippy="{
                          content: 'Download Invoice',
                          animation: 'scale',
                          animateFill: false,
                          theme: 'tomato',
                        }"
                        class="focus:outline-none inline-flex cursor-pointer items-center rounded-md border border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800"
                      >
                        <DownloadIcon
                          class="h-5 w-5 flex-shrink-0 text-gray-400"
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";
import { DownloadIcon, DocumentTextIcon } from "@heroicons/vue/solid";
var dayjs = require("dayjs");

export default {
  props: {
    payments: {
      type: Array,
      required: true,
    },
  },
  components: {
    DownloadIcon,
    DocumentTextIcon,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const formatDate = (value) => {
      return dayjs(value).format("MMMM D, YYYY");
    };

    const formatCurrency = (value) => {
      let currencyValue = value / 100;
      return `$${currencyValue}`;
    };

    return {
      formatDate,
      formatCurrency,
    };
  },
};
</script>

<style lang="scss" scoped></style>
