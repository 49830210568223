<template>
  <div class="flex justify-between">
    <div class="flex-shrink-0">
      <InformationCircleIcon
        v-tippy="{
          content: 'Add new credit card to make it current',
          animation: 'scale',
          animateFill: false,
          theme: 'tomato',
        }"
        class="mt-3 h-5 w-5 text-blue-400"
        aria-hidden="true"
      />
    </div>
    <button
      type="button"
      @click="onEdit"
      class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
    >
      New Card
    </button>
  </div>

  <TransitionRoot
    :show="true"
    enter="transition-opacity duration-75"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-150"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div
      v-for="(paymentDetail, index) in paymentDetails"
      :key="paymentDetail.id"
    >
      <div class="mt-5">
        <div
          class="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between"
        >
          <h4 class="sr-only">{{ paymentDetail.card.brand }}</h4>
          <div class="sm:flex sm:items-start">
            <svg
              v-if="paymentDetail.card.brand === 'visa'"
              class="h-8 w-auto sm:h-6 sm:flex-shrink-0"
              viewBox="0 0 36 24"
              aria-hidden="true"
            >
              <rect width="36" height="24" fill="#224DBA" rx="4" />
              <path
                fill="#fff"
                d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
              />
            </svg>
            <svg
              v-if="paymentDetail.card.brand === 'amex'"
              class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--32-svg"
              height="32"
              width="32"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <g fill="none" fill-rule="evenodd">
                <path fill="#0193CE" d="M0 0h32v32H0z"></path>
                <path
                  d="M17.79 18.183h4.29l1.31-1.51 1.44 1.51h1.52l-2.2-2.1 2.21-2.27h-1.52l-1.44 1.51-1.26-1.5H17.8v-.85h4.68l.92 1.18 1.09-1.18h4.05l-3.04 3.11 3.04 2.94h-4.05l-1.1-1.17-.92 1.17h-4.68v-.84zm3.67-.84h-2.53v-.84h2.36v-.83h-2.36v-.84h2.7l1.01 1.26-1.18 1.25zm-14.5 1.68h-3.5l2.97-6.05h2.8l.35.67v-.67h3.5l.7 1.68.7-1.68h3.31v6.05h-2.63v-.84l-.34.84h-2.1l-.35-.84v.84H8.53l-.35-1h-.87l-.35 1zm9.96-.84v-4.37h-1.74l-1.4 3.03-1.41-3.03h-1.74v4.04l-2.1-4.04h-1.4l-2.1 4.37h1.23l.35-1h2.27l.35 1h2.43v-3.36l1.6 3.36h1.05l1.57-3.36v3.36h1.04zm-8.39-1.85l-.7-1.85-.87 1.85h1.57z"
                  fill="#FFF"
                ></path>
              </g>
            </svg>
            <svg
              v-if="paymentDetail.card.brand === 'mastercard'"
              class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--32-svg"
              height="32"
              width="32"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h32v32H0z" fill="#000"></path>
                <g fill-rule="nonzero">
                  <path
                    d="M13.02 10.505h5.923v10.857H13.02z"
                    fill="#ff5f00"
                  ></path>
                  <path
                    d="M13.396 15.935a6.944 6.944 0 0 1 2.585-5.43c-2.775-2.224-6.76-1.9-9.156.745s-2.395 6.723 0 9.368 6.38 2.969 9.156.744a6.944 6.944 0 0 1-2.585-5.427z"
                    fill="#eb001b"
                  ></path>
                  <path
                    d="M26.934 15.935c0 2.643-1.48 5.054-3.81 6.21s-5.105.851-7.143-.783a6.955 6.955 0 0 0 2.587-5.428c0-2.118-.954-4.12-2.587-5.429 2.038-1.633 4.81-1.937 7.142-.782s3.811 3.566 3.811 6.21z"
                    fill="#f79e1b"
                  ></path>
                </g>
              </g>
            </svg>
            <div class="mt-3 sm:mt-0 sm:ml-4">
              <div class="text-sm font-medium text-gray-900">
                Ending with {{ paymentDetail.card.last4 }}
              </div>
              <div class="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                <div>
                  Expires {{ paymentDetail.card.exp_month }}/{{
                    paymentDetail.card.exp_year
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0" v-if="index !== 0">
            <button
              @click="onDelete(paymentDetail.id)"
              type="button"
              class="focus:outline-none inline-flex items-center rounded-md border border-red-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            >
              <TrashIcon class="h-5 w-5 text-red-400" />
            </button>
          </div>
          <div v-else>
            <span
              class="inline-flex items-center rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800"
            >
              <svg
                class="mr-1.5 h-2 w-2 text-indigo-400"
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx="4" cy="4" r="3" />
              </svg>
              Current
            </span>
          </div>
        </div>
      </div>
    </div>
  </TransitionRoot>

  <div class="absolute bottom-0.5 right-6">
    <button
      type="button"
      @click="cancelSubscription"
      class="inline-flex items-center rounded-md border border-transparent border-red-300 px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800"
    >
      <span class="ml-2">Cancel Subscription</span>
    </button>
  </div>
</template>

<script>
import { reactive } from "vue";
import { TransitionRoot } from "@headlessui/vue";
import { TrashIcon, InformationCircleIcon } from "@heroicons/vue/outline";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

export default {
  components: { TransitionRoot, TrashIcon, InformationCircleIcon },
  directives: {
    tippy: directive,
  },
  props: {
    paymentDetails: {
      type: Array,
      required: true,
    },
  },
  emits: ["onEdit"],
  setup(_, { emit }) {
    const state = reactive({
      showUpdatePaymentForm: false,
    });

    const cancelSubscription = () => {
      emit("cancelSubscription");
    };

    const onEdit = () => {
      emit("onEdit", true);
    };

    const onDelete = (id) => {
      emit("onDelete", id);
    };

    return {
      state,
      onEdit,
      onDelete,
      cancelSubscription,
    };
  },
};
</script>

<style>
.bt {
  border-top: 1px solid #e6e6e6;
  padding-top: 16px;
}
</style>
