<template>
  <section aria-labelledby="plan_heading">
    <div class="shadow sm:overflow-hidden sm:rounded-md">
      <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div class="flex justify-between">
          <h2
            id="plan_heading"
            class="text-left text-lg font-medium leading-6 text-gray-900"
          >
            Plan
          </h2>
          <div>
            <!--<button type="button"
                      class="ml-4 inline-flex items-center rounded-md border border-transparent border-blue-300 px-3 py-2 text-sm font-medium leading-4 text-blue-400 shadow-sm"
                      @click="openUpdatePayment">
                  <CreditCardIcon class="h-5 w-5" />
                  <span class="ml-2">Update Payment</span>
              </button>-->
            <button
              type="button"
              class="ml-4 inline-flex items-center rounded-md border border-transparent border-blue-300 px-3 py-2 text-sm font-medium leading-4 text-blue-400 shadow-sm"
              @click="openCustomerPortal"
            >
              <CreditCardIcon class="h-5 w-5" />
              <span class="ml-2">Update Subscription</span>
            </button>
          </div>
        </div>

        <RadioGroup v-model="selected">
          <RadioGroupLabel class="sr-only"> Pricing plans </RadioGroupLabel>

          <div class="relative -space-y-px rounded-md bg-white">
            <RadioGroupOption
              as="template"
              v-for="(plan, planIdx) in formattedPlans"
              :key="plan.name"
              :value="plan"
              v-slot="{ checked, active }"
            >
              <div
                :class="[
                  planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  checked
                    ? 'z-2 border-gray-200 bg-gray-50'
                    : 'border-gray-200',
                  active ? 'z-2 border-gray-200 bg-gray-50' : 'border-gray-200',
                  'focus:outline-none relative flex cursor-pointer flex-col border p-4 md:grid md:grid-cols-3 md:pl-4 md:pr-6',
                ]"
              >
                <div class="flex items-center text-sm">
                  <span
                    :class="[
                      checked
                        ? 'border-transparent bg-purple-500'
                        : 'border-gray-300 bg-white',
                      active
                        ? 'border-transparent bg-purple-500'
                        : 'border-gray-300 bg-white',
                      'flex h-4 w-4 items-center justify-center rounded-full border',
                    ]"
                    aria-hidden="true"
                  >
                    <span class="h-1.5 w-1.5 rounded-full bg-white" />
                  </span>
                  <RadioGroupLabel
                    as="span"
                    class="ml-3 font-medium text-gray-900"
                    >{{ plan.name }}</RadioGroupLabel
                  >
                </div>

                <RadioGroupDescription
                  class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
                >
                  <div v-if="plan.tiersMonthly === null">
                    <span
                      v-if="!annualBilling"
                      :class="[
                        checked ? 'text-gray-900' : 'text-gray-900',
                        'font-medium',
                      ]"
                      >${{ plan.priceMonthly }} / mo</span
                    >
                    <span
                      v-else
                      :class="checked ? 'text-gray-900' : 'text-gray-900'"
                      >(${{ plan.priceYearly }} / yr)</span
                    >
                  </div>
                  <div v-else>
                    <div v-if="!annualBilling">
                      <div
                        v-for="tier in plan.tiersMonthly"
                        :key="tier"
                        :class="[
                          checked ? 'text-gray-900' : 'text-gray-900',
                          'font-medium',
                        ]"
                      >
                        ${{ tier.flat_amount_decimal / 100 }} {{ " / " }}
                        {{ tier.up_to !== null ? tier.up_to : "Unlimited" }}
                        users / mo
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-for="tier in plan.tiersYearly"
                        :key="tier"
                        :class="[
                          checked ? 'text-gray-900' : 'text-gray-900',
                          'font-medium',
                        ]"
                      >
                        ${{ tier.flat_amount_decimal / 100 }}
                        {{ tier.up_to !== null ? tier.up_to : "Unlimited" }}
                        users / yr
                      </div>
                    </div>
                  </div>
                </RadioGroupDescription>
                <RadioGroupDescription
                  :class="[
                    checked ? 'text-gray-700' : 'text-gray-500',
                    'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right',
                  ]"
                  >{{ plan.description }}</RadioGroupDescription
                >
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>

        <SwitchGroup as="div" class="flex items-center">
          <Switch
            v-model="annualBilling"
            :class="[
              annualBilling ? 'bg-purple-500' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
            ]"
          >
            <span class="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              :class="[
                annualBilling ? 'translate-x-5' : 'translate-x-0',
                'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              ]"
            />
          </Switch>
          <SwitchLabel as="span" class="ml-3">
            <span class="text-sm font-medium text-gray-900"
              >Annual billing
            </span>
            <span class="text-sm text-gray-500">(Save {{ percentage }}%)</span>
          </SwitchLabel>
        </SwitchGroup>
      </div>
      <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
        <!-- <span
          v-if="selected !== undefined && plan.tiersMonthly !== undefined"
          class="mr-8 text-sm text-gray-400"
        >
          You will be charged ${{ calculatePrice(selected, annualBilling) }}
          {{ annualBilling ? "per year" : "per month" }}
        </span> -->

        <button
          @click="onSubmit"
          class="inline-flex justify-center rounded-md border border-transparent bg-purple-500 py-2 px-4 text-sm font-medium text-white shadow-sm"
        >
          Save
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import API from "@/api";

import { CreditCardIcon } from "@heroicons/vue/outline";

export default {
  components: {
    CreditCardIcon,
    RadioGroup,
    RadioGroupOption,
    RadioGroupLabel,
    RadioGroupDescription,
    Switch,
    SwitchLabel,
    SwitchGroup,
  },
  props: {
    plans: {
      type: Array,
      required: true,
    },
    currentPlan: {
      type: Object,
      required: true,
    },
    annualBillingEnabled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formattedPlans = ref([]);
    const selected = ref({});
    const annualBilling = ref(props.annualBillingEnabled);

    const openCustomerPortal = async () => {
      try {
        const response = await API.Organization.Subscriptions.customerPortal();

        window.open(`${response.data.Item}`, "_blank");
      } catch (e) {
        console.error(e);
      }
    };

    const openUpdatePayment = () => {
      emit("openUpdatePayment");
    };

    const cancelSubscription = () => {
      emit("cancelSubscription");
    };

    const onSubmit = () => {
      const id = annualBilling.value
        ? selected.value.idYearly
        : selected.value.idMonthly;

      emit("save", id);
    };

    const handlePlans = (plans) => {
      const activePlans = plans.filter((plan) => plan.product.active === true);

      const standardPlans = activePlans.filter(
        (plan) => plan.product.name === "Standard Plan"
      );

      const enterprisePlans = activePlans.filter(
        (plan) => plan.product.name === "Enterprise Plan"
      );

      const standardYearlySubscription = standardPlans.find(
        (plan) => plan.nickname === "Standard Yearly Subscription"
      );

      const standardMonthlySubscription = standardPlans.find(
        (plan) => plan.nickname === "Standard Monthly Subscription"
      );

      const enterpriseMonthlySubscription = enterprisePlans.find(
        (plan) => plan.nickname === "Enterprise Monthly Subscription"
      );

      const enterpriseYearlySubscription = enterprisePlans.find(
        (plan) => plan.nickname === "Enterprise Yearly Subscription"
      );

      formattedPlans.value.push({
        idMonthly: standardMonthlySubscription.id,
        idYearly: standardYearlySubscription.id,
        name: standardMonthlySubscription.product.name,
        priceMonthly: standardMonthlySubscription.amount / 100,
        priceYearly: standardYearlySubscription.amount / 100,
        tiersMonthly: standardMonthlySubscription.tiers,
        tiersYearly: standardYearlySubscription.tiers,
        description: standardMonthlySubscription.product.description,
      });

      formattedPlans.value.push({
        idMonthly: enterpriseMonthlySubscription.id,
        idYearly: enterpriseYearlySubscription.id,
        name: enterpriseMonthlySubscription.product.name,
        priceMonthly: enterpriseMonthlySubscription.amount / 100,
        priceYearly: enterpriseYearlySubscription.amount / 100,
        tiersMonthly: enterpriseMonthlySubscription.tiers,
        tiersYearly: enterpriseYearlySubscription.tiers,
        description: enterpriseMonthlySubscription.product.description,
      });

      if (props.currentPlan.id === standardMonthlySubscription.id) {
        selected.value = formattedPlans.value[0];
        annualBilling.value = false;
      } else if (props.currentPlan.id === enterpriseMonthlySubscription.id) {
        selected.value = formattedPlans.value[1];
        annualBilling.value = false;
      } else if (props.currentPlan.id === standardYearlySubscription.id) {
        selected.value = formattedPlans.value[0];
        annualBilling.value = true;
      } else if (props.currentPlan.id === enterpriseYearlySubscription.id) {
        selected.value = formattedPlans.value[1];
        annualBilling.value = true;
      }
    };

    const percentage = computed(() => {
      if (formattedPlans.value.length === 0) return 0;
      return (
        (formattedPlans.value[0].priceMonthly /
          formattedPlans.value[0].priceYearly) *
        100
      );
    });

    const calculatePrice = (plan, annualBilling) => {
      if (annualBilling && plan.tiersYearly !== null) {
        return plan.tiersYearly[0].flat_amount_decimal / 100;
      }

      if (!annualBilling && plan.tiersMonthly !== null) {
        return plan.tiersMonthly[0].flat_amount_decimal / 100;
      }

      if (!annualBilling && plan.tiersYearly === null) {
        return plan.priceMonthly;
      }

      if (annualBilling && plan.tiersYearly === null) {
        return plan.priceYearly;
      }
    };

    onMounted(() => {
      handlePlans(props.plans);
    });

    return {
      selected,
      annualBilling,
      formattedPlans: formattedPlans,
      openUpdatePayment,
      cancelSubscription,
      percentage,
      calculatePrice,
      onSubmit,
      openCustomerPortal,
    };
  },
};
</script>
