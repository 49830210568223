<template>
  <div class="layout" v-loading="loading">
    <Header />

    <Plan
      v-if="plans.length > 0"
      :plans="plans"
      :currentPlan="currentPlan"
      :annualBillingEnabled="annualBillingEnabled"
      @openUpdatePayment="openUpdatePayment"
      @cancelSubscription="cancelSubscription"
      @save="handleSubmit"
    />

    <BillingHistory v-if="payments.length > 0" :payments="payments" />
  </div>
  <UpdatePaymentModal
    v-if="state.toggleUpdatePayment"
    :show="state.toggleUpdatePayment"
    @onClose="state.toggleUpdatePayment = false"
    @cancelSubscription="cancelSubscription"
  />
  <CancelSubscriptionModal
    :show="state.toggleCancelSubscription"
    @onSubmit="deleteSubscription"
    @onClose="state.toggleCancelSubscription = false"
  />
</template>

<script>
import { onMounted, ref, reactive } from "vue";

import UpdatePaymentModal from "./components/_updatePayment.vue";
import useSubscriptionRepository from "@/composable/useSubscriptionRepository";
import BillingHistory from "./components/_billingHistory.vue";
import Header from "./components/_header.vue";
import Plan from "./components/_plan.vue";
import CancelSubscriptionModal from "./components/_cancelSubscription.vue";

export default {
  components: {
    CancelSubscriptionModal,
    UpdatePaymentModal,
    BillingHistory,
    Header,
    Plan,
  },
  setup() {
    const {
      loading,
      plans,
      payments,
      currentPlan,
      getPlans,
      updatePlan,
      getPayments,
      getCurrentPlan,
    } = useSubscriptionRepository();

    const open = ref(false);
    const annualBillingEnabled = ref(false);

    const state = reactive({
      toggleUpdatePayment: false,
      toggleCancelSubscription: false,
    });

    const openUpdatePayment = () => {
      state.toggleUpdatePayment = true;
    };

    const cancelSubscription = () => {
      state.toggleCancelSubscription = true;
    };

    const deleteSubscription = async () => {};

    const handleSubmit = (id) => {
      const data = {
        subscriptionId: "",
        quantity: 0,
        newPlanId: id,
      };

      updatePlan(data);
    };

    onMounted(() => {
      Promise.all([getPlans(), getPayments(), getCurrentPlan()]);
      // getCurrentPlan();
      // getPayments();
      // getPlans();
    });

    return {
      state,
      plans,
      payments,
      open,
      loading,
      handleSubmit,
      currentPlan,
      annualBillingEnabled,
      openUpdatePayment,
      cancelSubscription,
      deleteSubscription,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow-y: scroll;
  width: 100%;
  position: relative;
}
</style>
