<template>
  <TransitionRoot
    :show="true"
    enter="transition-opacity duration-75"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-150"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div>
      <div class="flex justify-between">
        <p>Payment details</p>
        <LockClosedIcon
          v-tippy="{
            content: 'Transactions are encrypted and secure',
            animation: 'scale',
            animateFill: false,
            theme: 'tomato',
          }"
          class="h-5 w-5 text-gray-500"
        />
      </div>
      <div class="px-2 pt-6">
        <div ref="cardElement"></div>
        <div
          id="card-element-errors"
          class="mt-2 rounded-md p-4 text-left text-red-400"
          role="alert"
        ></div>
        <div class="mt-2 flex justify-between">
          <button
            type="button"
            @click="onCancel"
            class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:text-sm"
          >
            Cancel
          </button>
          <button
            type="button"
            @click="onSubmit"
            class="inline-flex justify-center rounded-md border border-transparent bg-purple-500 py-2 px-4 text-sm font-medium text-white shadow-sm"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script>
let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK),
  elements = stripe.elements(),
  card = undefined;

let style = {
  base: {
    padding: "4px",
    fontWeight: 300,
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "18px",
    fontSmoothing: "antialiased",
  },
  invalid: {
    iconColor: "rgb(220, 38, 38)",
    color: "rgb(220, 38, 38)",
  },
};

import { TransitionRoot } from "@headlessui/vue";
import { LockClosedIcon } from "@heroicons/vue/solid";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

import { onMounted, onUnmounted, ref } from "vue";

export default {
  components: { LockClosedIcon, TransitionRoot },
  directives: {
    tippy: directive,
  },
  setup(_, { emit }) {
    const cardElement = ref(null);

    const displayError = (event) => {
      let displayError = document.getElementById("card-element-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    };

    const onSubmit = () => {
      stripe
        .createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            name: "", // Name of the card holder
          },
        })
        .then((response) => {
          emit("paymentDetailsId", response.paymentMethod.id);
        });
    };

    const onCancel = () => {
      emit("cancel");
    };

    onMounted(() => {
      card = elements.create("card", { style: style });
      card.mount(cardElement.value);

      card.on("change", function (event) {
        displayError(event);
      });
    });

    onUnmounted(() => {
      card.destroy();
    });

    return {
      cardElement,
      displayError,
      onSubmit,
      onCancel,
    };
  },
};
</script>

<style lang="scss" scoped></style>
